import React from "react"
import styled from "styled-components"
import { SectionTitle } from "./Section-Title"

const Container = styled.div`
  padding: 80px 40px;
  background: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`
const Quote = styled.h1`
  font-weight: 100;
  max-width: 700px;
  font-size: 50px;
  line-height: 65px;
  margin: 0;

  ::after {
    content: "";
    display: block;
    background-color: black;
    width: 10%;
    height: 3px;
    margin: 40px auto;
  }
`
const Subtitle = styled.p`
  margin: 0;
  font-weight: 600;
  font-family: "Articulat";
`

const AboutQuoteSection = props => (
  <Container>
    <Quote>“A confident smile makes a lasting impression.”</Quote>
    <Subtitle>-Dr. Shenilee Hazell</Subtitle>
  </Container>
)

export default AboutQuoteSection
