import React from "react"
import styled from "styled-components"
import { Colors } from "../colors/Colors"
import shen from "../images/drshen.jpg"

const Container = styled.div`
  display: flex;
  flex-direction: ${props => (props.odd ? "row-reverse" : "row")};
  align-items: center;
  justify-content: flex-start;

  margin-bottom: 40px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`
const ImageContainer = styled.div`
  position: relative;
  height: 450px;
  width: 100%;
  object-fit: cover;
  min-width: 300px;
  margin: 0;

  margin-right: ${props => !props.odd && "25px"};
  margin-left: ${props => props.odd && "25px"};

  @media (max-width: 600px) {
    margin: 0;
    min-width: 250px;
    margin-bottom: 10px;
  }
`
const Avatar = styled.img`
  width: 100%;
  margin: 0;
  object-fit: cover;
  height: 100%;
`
const CopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 600px) {
    margin-top: 15px;
  }
`
const Role = styled.h3`
  font-weight: bold;
  font-family: "Articulat";
  font-size: 14px;
  margin: 0 0 5px 0;
`
const Name = styled.h1`
  color: ${Colors.gold};
  font-family: "Playfair Display";
`
const Copy = styled.p`
  margin: 0;
  font-family: "Articulat";
  font-weight: 100;
  font-size: 16px;
  line-height: 30px;
  color: #666666;
  font-weight: 300;
`

const TeamAboutItem = props => (
  <Container odd={props.odd}>
    <ImageContainer>
      <Avatar odd={props.odd} src={shen} />
    </ImageContainer>
    <CopyContainer>
      <Role>{props.role}</Role>
      <Name>{props.name}</Name>
      <Copy>{props.copy}</Copy>
    </CopyContainer>
  </Container>
)

export default TeamAboutItem
