import React from "react"
import styled from "styled-components"
import { SectionTitle } from "./Section-Title"

const Container = styled.div`
  padding: 40px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`
const Copy = styled.p`
  margin: 0;
  max-width: 650px;
  font-family: "Articulat";
  font-weight: 100;
  font-size: 16px;
  line-height: 170%;
`

const AboutSection = props => (
  <Container>
    <SectionTitle>The Smile Inn Experience</SectionTitle>
    <Copy>
      At Smile Inn, we aim to provide you with a full dental experience to
      ensure that all your dental needs are met. Our hand selected team aims to
      exceed expectations and provide a different experience in a relaxing and
      friendly environment.
    </Copy>
  </Container>
)

export default AboutSection
