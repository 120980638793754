import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import AboutSection from "../components/about-section"
import AboutQuoteSection from "../components/about-quote"
import TeamAboutItem from "../components/team-about-item"
import teamImg from "../images/aboutHeader.jpg"
import PageHeader from "../components/page-header"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import IntroHeader from "../components/intro-header"
import AboutIntroHeader from "../components/about-intro-header"

const TeamSection = styled.div`
  padding: 50px 20px 0;
  max-width: 900px;
  margin: 0 auto;
`
const FooterImg = styled.img`
  width: 100%;
  max-height: 800px;
  object-fit: cover;
  margin: 0;
`

const AboutPage = ({ data }) => {
  const aboutData = data.wordpressPage.acf
  const gallery = aboutData?.gallery ?? []
  return (
    <Layout>
      <SEO title="About Us" />
      <AboutIntroHeader slides={gallery} copies={[]} />
      {/* <PageHeader gallery={gallery} image={teamImg} /> */}
      <AboutSection />
      <AboutQuoteSection />
      <TeamSection>
        {team.map((member, index) => (
          <TeamAboutItem
            key={index}
            odd={index % 2 === 1}
            name={member.name}
            role={member.role}
            copy={member.copy}
          />
        ))}
      </TeamSection>
      <FooterImg src={require("../images/aboutbtm.webp")} />
    </Layout>
  )
}

export default AboutPage

const team = [
  {
    name: "Dr. Shenilee Hazell",
    role: "Founder and Lead Dentist",
    copy:
      "Dr Shenilee Hazell Graduated from the  University of Bristol in 2007. After graduating from Bristol, she settled in the City of London where she has been successfully practising dentistry for over 11 years, providing aesthetic and cosmetic dentistry. Over the years she has undergone extensive training in the  North America, UK and Europe. Dr Hazell has a passion in restorative dentistry, aligning teeth using Invisalign and other short term cosmetic alignment systems and providing patients with the latest effective tooth whitening treatments.",
  },
]

export const aboutQuery = graphql`
  query aboutPageQuery {
    wordpressPage(id: { eq: "93c4c0de-4e24-5607-bbe2-1436452c7f16" }) {
      acf {
        gallery {
          source_url
          alt_text
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        quote
      }
    }
  }
`
